<template>
  <div class="product-item-container">
    <span class="index-number">{{ index + 1 }}</span>
    <div class="custom-field">
      <div class="title">
        <span>Question:</span>
        {{ field.question }}
      </div>
      <div class="type">
        <div class="field-type">
          <span>Field Type:</span>
          {{ fieldTypeName }}
        </div>
        <div v-if="field.created_at" class="created-at">
          <span>Created:</span>
          {{ formatDate(new Date(field.created_at)) }}
        </div>
      </div>
      <div class="buttons">
        <button class="edit-button" type="button" @click="$emit('edit', field)">
          Edit
        </button>
        <button
          class="delete-button"
          type="button"
          @click="$emit('delete', field)"
        >
          Delete
        </button>

        <button class="edit-button" type="button" @click="hideField">
          {{ field.hidden ? "Publish" : "Hide" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomFieldTile",
  props: {
    index: Number,
    field: Object,
    fieldTypes: Array
  },
  computed: {
    fieldTypeName() {
      return this.fieldTypes.find(t => t.value === this.field.type).label;
    }
  },
  methods: {
    hideField() {
      this.$emit('hide', this.field);
      let val = true;
      if(this.field.hidden) {
        val = false;
      }
      this.field.hidden = val;
      this.$forceUpdate();
      if (val) {
        this.$toast("Custom Field was hidden!", { type: "success" });
      } else {
        this.$toast("Custom Field was published!", { type: "success" });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.product-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-number {
  background-color: var(--primary-green);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin: 1rem;
}
.custom-field {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 15px;
  margin: 10px;
  border: 1px solid var(--dashboard-border);
  border-radius: 8px;
  cursor: grab;
  text-align: center;
  gap: 24px;

  .title {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 8px;

    & > span {
      font-weight: bold;
      color: var(--primary-green);
    }
  }

  .type {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .field-type,
    .created-at {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      justify-content: center;

      & > span {
        color: var(--primary-green);
        font-weight: bold;
      }
    }
  }

  .buttons {
    display: flex;
    margin: 0 -10px;

    .edit-button,
    .delete-button {
      display: flex;
      justify-content: center;
      background-color: var(--secondary-green);
      color: white;
      width: 50%;
      font-size: 15px;
      border-radius: 5px;
      border: none;
      height: 30px;
      padding: 5px;
      margin: 0 10px;
      cursor: pointer;
      z-index: 10;
    }
  }
}
</style>
