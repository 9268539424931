<template>
  <EventManagementPage
    title="Custom Fields (Q&A)"
    :breadcrumbs="breadcrumbs"
    @load="onLoad"
  >
    <ConfirmationModal ref="confirmationModal" />
    <FormulateForm class="custom-fields" ref="form" v-model="formValues">
      <h2>Getting Started</h2>
      <p class="b1">
        You can ask your customers any questions you'd like on checkout through
        various types of fields.
      </p>
      <p class="b1">
        Note, the more questions you add, the longer checkout will take. The
        optimal number of custom fields are 1 - 5. Anymore than that and we have
        found that there is some buyer drop off.
      </p>
      <p class="b1">
        We have also limited the type of fields available based on research into
        what types of fields buyers feel comfortable and not burdened by, which
        is why there are no long form text options.
      </p>
      <p class="b1">
        View the example below. When you don't need the you can collapse it by
        clicking the header.
      </p>

      <CustomFieldsExample v-model="exampleActive" />

      <div class="divider"></div>

      <CustomFieldForm
        class="product-item-form"
        :field-types="fieldTypeOptions"
        :applicable-to-types="applicableToOptions"
        :editing-item="editingItem"
        :loading="loading"
        @save-field="onFieldSaved"
      />

      <div class="divider"></div>

      <div>
        <h3>Sort Custom Fields</h3>
        <p class="b1">
          If you would like to re-order your custom questions please drag and
          drop the tiles to suit your needs, how easy is that? 👊
        </p>
        <Draggable
          class="merch-items"
          :list="formValues.customFields"
          v-bind="draggableOptions"
          @sort="onChangeSort"
        >
          <CustomFieldTile
            v-for="(field, index) in formValues.customFields"
            :field="field"
            :key="index"
            :index="index"
            :field-types="fieldTypeOptions"
            @edit="onEdit(field, index)"
            @delete="onDelete(field, index)"
            @hide="onHide(field, index)"
          />
        </Draggable>
      </div>
    </FormulateForm>
  </EventManagementPage>
</template>

<script>
import Draggable from "vuedraggable";
import CustomFieldsExample from "../components/customFields/CustomFieldsExample.vue";
import CustomFieldForm from "../components/customFields/CustomFieldForm.vue";
import CustomFieldTile from "../components/customFields/CustomFieldTile.vue";
import EventManagementPage from "../components/EventManagementPage.vue";
import ConfirmationModal from "../../../shared/components/modals/ConfirmationModal.vue";

export default {
  name: "CustomFields",
  components: {
    EventManagementPage,
    CustomFieldTile,
    CustomFieldForm,
    CustomFieldsExample,
    Draggable,
    ConfirmationModal
  },
  data() {
    return {
      eventID: null,
      editingItem: null,
      editingIndex: -1,
      exampleActive: false,
      loading: false,
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Custom Fields", null]
      ],
      draggableOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        forceFallback: true,
        ghostClass: "ghost"
      },
      fieldTypeOptions: [
        { label: "Multiple Choice", value: "MULTIPLE_CHOICE" },
        {
          label: "Multi-select Checkboxes",
          value: "MULTI_SELECT"
        },
        {
          label: "Dropdown",
          value: "DROPDOWN"
        },
        {
          label: "Emoji Rankings",
          value: "EMOJI_RANKINGS"
        },
        {
          label: "Single line text",
          value: "TEXT"
        }
      ],
      applicableToOptions: [
        { label: "In-Person Tickets", value: "IN_PERSON_TICKETS" },
        { label: "Virtual Access", value: "VIRTUAL_ACCESS" },
        { label: "Donations", value: "DONATIONS" },
        { label: "Products", value: "PRODUCTS" }
      ],
      formValues: {
        customFields: []
      }
    };
  },
  methods: {
    onFieldSaved(field) {
      this.loading = true;
      if (field.isNew && !field.isChanged) {
        field.sort_order = this.formValues.customFields.length;

        this.$axios
          .post(`/fields/${this.eventID}/create`, field)
          .then(response => {
            this.formValues.customFields.push(response.data.field);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$axios
          .post(`/fields/${field._id}/update`, field)
          .then(response => {
            this.formValues.customFields.splice(
              this.editingIndex,
              1,
              response.data.field
            );
            this.editingItem = null;
            this.editingIndex = -1;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onEdit(field, index) {
      field.isChanged = true;

      this.editingItem = {
        ...field
      };
      this.editingIndex = index;
    },
    onDelete(field, index) {
      this.$refs.confirmationModal.open({
        msg: "Are you sure you want to delete this custom field?",
        onConfirm: () => {
          this.$axios.post(`/fields/${field._id}/delete`).then(() => {
            this.formValues.customFields.splice(index, 1);
          });
        }
      });
    },
    onHide(field, index) {
      this.loading = true;
      this.$axios.post(`/fields/${field._id}/hide`).then(() => {
        this.formValues.customFields.splice(
          index,
          1,
          response.data.field
        );
      }).finally(() => {
        this.loading = false;
      });
    },
    onChangeSort() {
      this.$axios.post(`/fields/${this.eventID}/set-order`, {
        fields: this.formValues.customFields.map((field, index) => ({
          _id: field._id,
          sort_order: index
        }))
      });
    },
    onLoad({ id }) {
      this.eventID = id;
      this.$axios.get(`/fields/${id}`).then(response => {
        this.formValues.customFields = response.data.fields;
        this.exampleActive = response.data.fields.length === 0;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.custom-fields &::v-deep {
  .merch-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 414px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
